import React from "react";
import Icon from "../Icon";
import { IconTypes } from "@/types/common";
import styles from "./Button.module.scss";

export interface ButtonProps {
  text?: string | number;
  type?: "button" | "submit" | "reset" | undefined;
  title?: string;
  disabled?: boolean;
  style?: any;
  icon?: IconTypes | null;
  iconSize?: number;
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  onHover?: (event: React.MouseEvent) => void;
  onFocus?: (event: React.FocusEvent<HTMLButtonElement, Element>) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement, Element>) => void;
}

const Button: React.FC<ButtonProps> = ({
  text,
  className,
  style,
  title,
  disabled = false,
  icon,
  iconSize,
  children,
  onClick,
  onFocus,
  onBlur,
}) => {
  return (
    <button
      className={styles.commonButton + ` ${className || ''}`}
      disabled={disabled}
      onClick={onClick && onClick}
      onFocus={onFocus && onFocus}
      onBlur={onBlur && onBlur}
      title={title}
      type="button"
      aria-label={title}
      style={disabled ? { opacity: 0.5, cursor: 'auto', pointerEvents: 'none' } : {}}
    >
      {icon && (
        <span
          className={styles.buttonIcon}
          style={{ ...style, width: iconSize, height: iconSize }}
        >
          <Icon iconName={icon} side={iconSize} />
        </span>
      )}

      {text ? <span className={styles.buttonText}>{text}</span> : null}
      {children ? children : null}
    </button>
  );
}

export default Button;
