import {
  applySnapshot,
  destroy,
  flow,
  Instance,
  SnapshotOrInstance,
  types,
} from "mobx-state-tree";
import { ModalType } from "@/types/common";

const Notification = types.model({
  id: types.optional(types.number, 0),
  type: types.optional(types.enumeration(["info", "error", ""]), ""),
  message: types.optional(types.string, ""),
  timeout: types.optional(types.number, 3000),
});

const Interface = types
  .model({
    notifications: types.optional(types.array(Notification), []),
    isLoading: false,
    spinnerMessage: types.optional(types.string, ""),
    modalType: types.optional(types.string, ""),
    currentProjectId: types.optional(types.string, ""),
    deleteProjectId: types.optional(types.string, ""),
  })
  .actions((self) => {
    const setIsLoading = (value: boolean) => {
      self.isLoading = value;
    };
    const setSpinnerMessage = (value: string) => {
      self.spinnerMessage = value;
    };
    const pushNotification = (notification: NotificationType) => {
      const newNotification = Notification.create({
        ...notification,
        id: performance.now(),
      });
      applySnapshot(self.notifications, [
        ...self.notifications,
        newNotification,
      ]);

      delayedDeleteNotification(newNotification);
    };

    const delayedDeleteNotification = flow(function* (
      notification: NotificationInstanceType
    ) {
      yield new Promise((resolve) =>
        setTimeout(resolve, notification.timeout)
      ).then((timeout) => clearTimeout(timeout as NodeJS.Timeout));
      destroy(notification);
    });

    const setModalType = (type: ModalType) => {
      self.modalType = type;
    };

    const setCurrentProjectId = (id: string = '') => {
      self.currentProjectId = id;
      localStorage.setItem('currentProjectId', id);
    };

    const setDeleteProjectId = (id: string) => {
      self.deleteProjectId = id;
    };

    return {
      setIsLoading,
      setSpinnerMessage,
      pushNotification,
      delayedDeleteNotification,
      setModalType,
      setCurrentProjectId,
      setDeleteProjectId,
    };
  });

export type NotificationType = SnapshotOrInstance<typeof Notification>;
type NotificationInstanceType = Instance<typeof Notification>;

export { Interface };
