import { ChangeEvent, useState } from "react";
import store from "@/MST/store";
import Spinner from "../elements/Spinner";
import styles from "./DemoWidget.module.scss";
import axios from "axios";

type Props = {
  settings: {
    projectId: string;
    widgetHeaderColor: string;
    widgetBackgroundColor: string;
    widgetBorderRadius: string;
    widgetBorderColor: string;
    widgetBorderWidth: string;
  };
};

const DemoWidget: React.FC<Props> = ({ settings }) => {
  const {
    projectId,
    widgetHeaderColor,
    widgetBackgroundColor,
    widgetBorderRadius,
    widgetBorderColor,
    widgetBorderWidth,
  } = settings;

  const { pushNotification } = store.interface;
  const { _id: userId } = store.user;

  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
    event.key === "Enter" ? onQueryButtonClick() : null;

  const onQueryInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const onQueryButtonClick = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post("/getCompletion", {
        userId,
        projectId,
        question,
      });
      setResponse(data.response);
    } catch (e: any) {
      pushNotification({
        type: "error",
        message: "Error getting the responsse : \n" + e.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onClearClick = () => {
    setQuestion("");
    setResponse("");
  };

  return (
    <div
      className={styles.clarify_bot_chat_popup}
      style={{
        backgroundColor: widgetBackgroundColor,
        borderRadius: widgetBorderRadius + "px",
        borderColor: widgetBorderColor,
        borderWidth: widgetBorderWidth + "px",
      }}
    >
      <div
        className={styles.clarify_bot_header}
        style={{ backgroundColor: widgetHeaderColor }}
      >
        Help
        <button
          className={styles.clarify_bot_toggle_button}
          title="open / close"
        >
          {" "}
          _
        </button>
      </div>
      <div className={styles.clarify_bot_content}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.clarify_bot_input_wrapper}>
              <svg width="16" height="16" viewBox="0 0 16 16" focusable="false">
                <circle
                  cx="6"
                  cy="6"
                  r="5.5"
                  fill="none"
                  stroke="black"
                ></circle>
                <path
                  stroke="black"
                  strokeLinecap="round"
                  d="M15 15l-5-5"
                ></path>
              </svg>
              <input
                onChange={onQueryInputChange}
                onKeyPress={onInputKeyPress}
                value={question}
                className={styles.clarify_bot_question_input}
                placeholder="How can we help?"
              />
              <button
                className={styles.clarify_bot_clear_button}
                onClick={onClearClick}
                title="clear"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  focusable="false"
                >
                  <path
                    stroke="black"
                    strokeLinecap="round"
                    d="M3 13L13 3m0 10L3 3"
                  ></path>
                </svg>
              </button>
            </div>
            {response ? (
              <div className="clarify_bot_response_block">{response}</div>
            ) : (
              <button
                disabled={!question}
                title="Send question request"
                className={styles.clarify_bot_ask_button}
                onClick={onQueryButtonClick}
              >
                Ask!
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DemoWidget;
