import { useEffect, useRef } from "react";
import styles from "./FakeChatWindow.module.scss";

type Props = {
  request: string;
  response: string;
  styleChanged: boolean;
};
let count = 0;
const timeouts: NodeJS.Timeout[] = [];

/** Mechanism for autotyping in the fake chat window. */
function typingInterval(
  text: string,
  elementRef: React.RefObject<Partial<HTMLDivElement & HTMLInputElement>>
) {
  return new Promise((resolve) => {
    let inputInterval = setInterval(() => {
      try {
        if (count >= text.length) {
          elementRef.current?.nodeName === "INPUT"
            ? (elementRef.current!.value = text)
            : (elementRef.current!.innerText = text);
          count = 0;
          resolve(inputInterval);
        } else {
          elementRef.current?.nodeName === "INPUT"
            ? (elementRef.current!.value = text.slice(0, count))
            : (elementRef.current!.innerText = text.slice(0, count));
          count += 1;
        }
      } catch (e) {
        // this happens when navigating from the landing page, the elements references are not viable already, cause being unmounted.
        clearInterval(inputInterval);
      }
    }, 20);
    timeouts.push(inputInterval);
  }).then((inputInterval: any) => clearInterval(inputInterval));
}

const FakeChatWindow: React.FC<Props> = ({
  request,
  response,
  styleChanged = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const responseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    clearAllIntervals();
    count = 0;
    inputRef.current!.value = "";
    responseRef.current!.innerText = "";
    typingInterval(request, inputRef).then(() => {
      typingInterval(response, responseRef);
    });
  }, [request, response]);

  const clearAllIntervals = () => {
    timeouts.forEach((intervalId) => clearInterval(intervalId));
  };

  return (
    <div className={styles.fakeChatWindowWrapper}>
      <div
        className={
          styles.fakeChatWindow +
          (styleChanged ? ` ${styles.styleChanged}` : "")
        }
      >
        <div
          className={
            styles.fakeChatHeader +
            (styleChanged ? ` ${styles.styleChanged}` : "")
          }
        >
          clarify_bot
        </div>

        <div className={styles.fakeChatContent}>
          <div className={"inputWrapper"}>
            <input ref={inputRef} type="text" tabIndex={-1} />
          </div>
          <div ref={responseRef} className={styles.responceBlock}></div>
        </div>
      </div>
    </div>
  );
};

export default FakeChatWindow;
