import { types, Instance } from "mobx-state-tree";
import axios, { AxiosResponse } from "axios";
import { Interface } from "./interface";
import { User } from "./user";

// Given that the frontend is being already compiled in the backend repo, did not want to modify its code
// during the backend build and deployment. It is a bit sloppy way of doing this - but for the frontend to
// figure out if this is dev or prod, it just looks at the origin from where it is being served.
export const env =
  window.location.hostname === "clarify-bot.com" ? "production" : "development";

export const BASE_URL =
  env === "production" ? "https://clarify-bot.com" : "http://localhost:3300";

axios.defaults.baseURL = BASE_URL + "/api/v1";

axios.interceptors.response.use(
  (res: AxiosResponse) => res,
  (err: any) => {
    // 401 comes in case if the token is expired or invalid for some other reason, therefore - rejected by server.
    // In this case the popup will be shown with the incoming message about that from server (invoked from catch clause
    // anywhere down the road) -  and the page will be reloaded in 3 seconds. Done through interceptor so that there would be
    // no need to check the error for 401 code on every call to the private api route. Default case just passes all other errors
    // down the road as well, axios Error transformed to the standard Error object.
    switch (err.response?.data.code) {
      case 401: {
        localStorage.removeItem("token");
        axios.defaults.headers.common.Authorization = ``;
        const errorMsg =
          err.response?.data?.message + " Page will be reloaded in 3 sec." ||
          "Unknown error.";
        setTimeout(() => window.location.reload(), 3000);
        throw new Error(errorMsg);
      }
      default: {
        const errorMsg = err.response?.data.message || "Unknown error.";
        throw new Error(errorMsg);
      }
    }
  }
);

const store = types.model({
  user: types.optional(User, {}),
  interface: types.optional(Interface, {}),
});

export type StoreType = Instance<typeof store>;

export default store.create();
