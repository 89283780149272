export function createBackground() {
  const canvas = document.getElementById("canvas1");
  const ctx = canvas.getContext("2d");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  const currentColorValue = getComputedStyle(
    document.querySelector("html")
  ).getPropertyValue("--color-accent");

  const getColorWithOpacity = (opacity = 1) => {
    const colorsArray = /\((.*)\)/.exec(currentColorValue)[1].split(" ");
    colorsArray.push(opacity.toFixed(2));
    const color = "rgba(" + colorsArray.join(",") + ")";
    return color;
  };

  let particlesArray;

  window.addEventListener("resize", () => {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    init();
  });

  window.addEventListener("mousemove", (event) => {
   
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

    particlesArray.forEach((particle) => {
      particle.x -= event.movementX * 0.2 / particle.size ;
      particle.y -= event.movementY * 0.2 / particle.size ;
      particle.draw();
    });

    connect();
  });

  class Particle {
    constructor(id, x, y, size, color) {
      this.id = id;
      this.x = x;
      this.y = y;
      this.size = size;
      this.color = color;
    }

    // methd to draw individual particle.
    draw() {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
      ctx.fillStyle = currentColorValue;
      ctx.fill();
    }
  }

  // create particle array
  function init() {
    particlesArray = [];
    let numberOfParticles = (canvas.width * canvas.height) / 19000;
    for (let i = 0; i < numberOfParticles; i += 1) {
      let size = Math.random() * 5 + 1;
      let x =
        Math.random() * (window.innerWidth - size * 2 - size * 2) + size * 2;
      let y =
        Math.random() * (window.innerHeight - size * 2 - size * 2) + size * 2;
      let color = currentColorValue;
      particlesArray.push(
        new Particle(i, x, y, size, 30, color)
      );
    }

    particlesArray.forEach((particle) => {
      particle.draw();
    });
    connect();
  }

  function connect() {
    // check if the particles are close enough to each other to draw a line between them.

    for (let a = 0; a < particlesArray.length; a += 1) {
      for (let b = a; b < particlesArray.length; b += 1) {
        let distance =
          (particlesArray[a].x - particlesArray[b].x) ** 2 +
          (particlesArray[a].y - particlesArray[b].y) ** 2;
        if (distance < (canvas.width / 7) * (canvas.height / 7)) {
          const opacity = 1 - distance / 40000;
          ctx.strokeStyle = getColorWithOpacity(opacity);
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(particlesArray[a].x, particlesArray[a].y);
          ctx.lineTo(particlesArray[b].x, particlesArray[b].y);
          ctx.stroke();
        }
      }
    }
  }

  init();
}
