function validateURL(url: string) {
  try {
    return (
      !url.match(/ +/) && url.match(/(http:|https:)/) && Boolean(new URL(url))
    );
  } catch (error) {
    return false;
  }
}

function validateProjectName(projectName: string) {
  return projectName && projectName.match(/^[A-Z|a-z|0-9|-]+$/g);
}

export { validateURL, validateProjectName };
