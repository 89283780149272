import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import store from "@/MST/store";

import styles from "./Menu.module.scss";

type Props = {};

const Menu: React.FC<Props> = () => {
  const { currentProjectId } = store.interface;
  return (
    <ul className={styles.navList}>
      <li>
        <NavLink exact={true} to={`/cabinet/projects`}>
          Projects
        </NavLink>
      </li>
      {currentProjectId ? (
        <>
          <NavLink
            to={`/cabinet/settings`}
            activeClassName={styles.activeLink}
          >
            <li>Settings</li>
          </NavLink>
          <NavLink to={`/cabinet/billing`} activeClassName={styles.activeLink}>
            <li>Billing</li>
          </NavLink>
          {/* <NavLink to={`/cabinet/stats`} activeClassName={styles.activeLink}>
            <li>Stats</li>
          </NavLink> */}
        </>
      ) : null}
    </ul>
  );
};

export default observer(Menu);
