import { NotificationType } from "@/MST/interface";
import styles from './Notification.module.scss';

const Notification: React.FC<NotificationType> = ({type, message}) => {
  return (
    <div className={styles.notification}>
      {type}, {message}
    </div>
  );
};

export default Notification;