import React from "react";
import store from "@/MST/store";
import Button from "@/components/elements/Button";

import { ModalProps } from "@/types/common";

import styles from "../modalForm.module.scss";

const DeleteProjectModal: React.FC<ModalProps> = ({ onClose }) => {
  const { deleteProject, getProjectById } = store.user;
  const { deleteProjectId, setCurrentProjectId, setIsLoading } =
    store.interface;

  const onDeleteClick = async () => {
    try {
      onClose();
      if (!deleteProjectId) return;
      setIsLoading(true);
      await deleteProject(deleteProjectId);
      setCurrentProjectId("");
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.modalForm}>
      <div className={styles.deletePrompt}>
        {" "}
        You sure you want to delete{" "}
        {getProjectById(deleteProjectId)?.projectName} project?
      </div>
      <div className={styles.buttonsWrapper}>
        <Button text="Delete" onClick={onDeleteClick} />
        <Button text="Cancel" onClick={onClose} />
      </div>
    </div>
  );
};

export default DeleteProjectModal;
