import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import store from "@/MST/store";
import AuthModal from "../AuthModal";
import CreateProjectModal from "../CreateProjectModal";
import DeleteProjectModal from "../DeleteProjectModal";
import DeleteProjectKBModal from "../DeleteProjectKBModal";

import styles from "../modalForm.module.scss";

const Modal: React.FC = () => {
  const { modalType, setModalType } = store.interface;
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    !!modalType ? dialogRef.current?.show() : dialogRef.current?.close();
  }, [modalType]);

  const onClose = () => {
    setModalType("");
  };

  const getModalByType = () => {
    switch (modalType) {
      case "auth": {
        return <AuthModal onClose={onClose} />;
      }
      case "projectCreate": {
        return <CreateProjectModal onClose={onClose} />;
      }
      case "projectDelete": {
        return <DeleteProjectModal onClose={onClose} />;
      }
      case "proejctKBDelete": {
        return <DeleteProjectKBModal onClose={onClose} />;
      }
    }
  };

  return (
    <dialog ref={dialogRef} onClose={onClose}>
      <div className={styles.modalForm}>{getModalByType()}</div>
    </dialog>
  );
};

export default observer(Modal);
