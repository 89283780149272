import { Link, useLocation } from "react-router-dom";
import styles from "./ProjectStatusLabel.module.scss";

const ProjectStatusLabel: React.FC<{
  isActive: boolean;
  stripeMode?: string;
}> = ({ isActive, stripeMode }) => {
  const { pathname } = useLocation();
  const stripeModeMessage = stripeMode === 'live' ? "" : " (stripe test mode)"
  const inactiveText = pathname === "/cabinet/projects"
    ? "Inactive"
    : "Activate subscription";
  return (
    <span className={styles.projectStatusLabel} data-status={isActive}>
      {isActive ? "Active" + stripeModeMessage : <Link to={"/cabinet/billing"}>{inactiveText + stripeModeMessage}</Link>}
    </span>
  );
};

export default ProjectStatusLabel;
