import React from "react";
import { observer } from "mobx-react-lite";
import { Menu, Notification } from "@/components";
import Button from "@/components/elements/Button";

import store from "@/MST/store";

import styles from "./AppBar.module.scss";

import { ReactComponent as IconLogo } from "@/img/icon_logo.svg";

type Props = {};

const AppBar: React.FC<Props> = () => {
  const { userIsAuthenticated, userEmail, userLogout } = store.user;

  const { notifications, setModalType } = store.interface;

  const onAuthBtnClick = () => {
    if (userIsAuthenticated) {
      onLogoutClick();
    } else {
      setModalType("auth");
    }
  };

  const onLogoutClick = () => {
    userLogout();
  };

  return (
    <header className={styles.appBar}>
      {notifications.length ? (
        <div className={styles.notificationsWrapper}>
          {notifications.map((notificationData) => (
            <Notification key={notificationData.id} {...notificationData} />
          ))}
        </div>
      ) : null}
      <div className={styles.logo}>
        <IconLogo />
      </div>

      <div className={styles.controlsWrapper}>
        {userIsAuthenticated ? <Menu /> : null}
        <div className={styles.authWrapper}>
          {userIsAuthenticated ? <div>{userEmail}</div> : null}
          <Button
            onClick={onAuthBtnClick}
            text={userIsAuthenticated ? "log out" : "log in / register"}
            icon={userIsAuthenticated ? "icon_exit" : "icon_login"}
          />
        </div>
      </div>
    </header>
  );
};

export default observer(AppBar);
