import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "@/components/elements/Button";
import store from "@/MST/store";

import { ModalProps, UserType } from "@/types/common";

import styles from "../modalForm.module.scss";

const AuthModal: React.FC<ModalProps> = ({ onClose }) => {
  const [registerMode, setRegisterMode] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>("");
  const [error, setError] = useState("");

  const { userRegister, userLogin } = store.user;

  const onRegisterClick = ({
    userEmail,
    userPassword,
    recaptchaValue,
  }: UserType & { recaptchaValue: string | null }) => {
    userRegister({ userEmail, userPassword, recaptchaValue });
    onClose();
  };

  const onLoginClick = ({ userEmail, userPassword }: UserType) => {
    userLogin({ userEmail, userPassword });
    onClose();
  };

  const onInputChange =
    (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      switch (type) {
        case "email":
          setUserEmail(value);
          break;
        case "password":
          setUserPassword(value);
          break;
        case "passwordRepeat":
          setPasswordRepeat(value);
          break;
      }
    };

  const loginClick = () => {
    setError("");
    setRegisterMode(false);
    if (!validateFields()) return;
    onLoginClick({ userEmail, userPassword });
  };

  const registerClick = () => {
    setError("");
    setRegisterMode(true);
    if (!validateFields() || !passwordRepeat) return;
    onRegisterClick({ userEmail, userPassword, recaptchaValue });
  };

  const closeClick = () => {
    clearForm();
    onClose();
  };

  const validateFields = () => {
    if (!userEmail || !userEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setError("email");
      return false;
    }
    if (!userPassword || userPassword.length < 8) {
      setError("password");
      return false;
    }
    if (registerMode && userPassword !== passwordRepeat) {
      setError("passwordRepeat");
      return false;
    }
    if (registerMode && !recaptchaValue) {
      setError("recaptcha");
      return false;
    }

    return true;
  };

  const clearForm = () => {
    setRegisterMode(false);
    setUserEmail("");
    setUserPassword("");
    setPasswordRepeat("");
    setError("");
  };

  return (
    <div className={styles.modalForm}>
      <Button
        title={"close"}
        className={styles.closeBtn}
        icon="icon_close"
        onClick={closeClick}
      />
      <div className={styles.fieldsWrapper}>
        <label>
          Email
          {error === "email" ? (
            <span className="error hintText">Please, enter the email.</span>
          ) : null}
          <div className="inputWrapper">
            <input
              autoFocus={true}
              value={userEmail}
              type="email"
              placeholder="email"
              onChange={onInputChange("email")}
            />
          </div>
        </label>

        <label>
          Password
          {error === "password" ? (
            <span className="error hintText">Please, enter the password.</span>
          ) : null}
          <div className="inputWrapper">
            <input
              value={userPassword}
              type="password"
              placeholder="password (minimum 8 characters)"
              onChange={onInputChange("password")}
            />
          </div>
        </label>

        {registerMode ? (
          <label>
            Password repeat
            {error === "passwordRepeat" ? (
              <span className="error hintText">
                Passwords do not match.
              </span>
            ) : null}
            <div className="inputWrapper">
              <input
                value={passwordRepeat}
                type="password"
                placeholder="password repeat"
                onChange={onInputChange("passwordRepeat")}
              />
            </div>
          </label>
        ) : null}
      </div>
      <div className={styles.buttonsWrapper}>
        <Button text="Login" onClick={loginClick} />
        <Button text="Register" onClick={registerClick} />
      </div>
      {registerMode && error === "recaptcha" ? (
        <span className="error hintText">
          Please, complete the check below.
        </span>
      ) : null}
      {registerMode ? (
        <ReCAPTCHA style={{marginTop: '5px'}}
          sitekey="6Ldh0xkqAAAAANLUvybZeXDnesZus6fL_Np9l6Ag"
          onChange={(value) => setRecaptchaValue(value)}
        />
      ) : null}
    </div>
  );
};

export default AuthModal;
