import React from "react";
import store from "@/MST/store";
import Button from "@/components/elements/Button";

import { ModalProps } from "@/types/common";

import styles from "../modalForm.module.scss";

const DeleteProjectKBModal: React.FC<ModalProps> = ({ onClose }) => {
  const { deleteKnowledgeBase, getProjectById } = store.user;
  const { currentProjectId } =
    store.interface;

  const onDeleteClick = async () => {
    if (!currentProjectId) return;
    onClose();
    await deleteKnowledgeBase(
      currentProjectId || "",
      getProjectById(currentProjectId)?.projectName || ""
    );
  };

  return (
    <div className={styles.modalForm}>
      <div className={styles.deletePrompt}>
        You sure you want to delete {" "}
        {getProjectById(currentProjectId)?.projectName} project knowledge base?
      </div>
      <div className={styles.buttonsWrapper}>
        <Button text="Delete" onClick={onDeleteClick} />
        <Button text="Cancel" onClick={onClose} />
      </div>
    </div>
  );
};

export default DeleteProjectKBModal;
