import {
  types,
  flow,
  getSnapshot,
  applySnapshot,
  getParent,
  SnapshotOrInstance,
} from "mobx-state-tree";
import axios from "axios";
import { BASE_URL, StoreType } from "./store";

import { NewSubscriptionData, UserType } from "@/types/common";
import { type } from "os";

const Project = types.model({
  _id: types.optional(types.string, ""),
  projectName: types.optional(types.string, ""),
  projectCreationDate: types.optional(types.string, ""),
  projectURL: types.optional(types.string, ""),
  projectIngestedData: types.optional(
    types.array(types.model({ fileName: "", size: "" })),
    []
  ),
  widgetSettings: types.optional(
    types.model({
      widgetHeaderColor: "",
      widgetBackgroundColor: "",
      widgetBorderColor: "",
      widgetBorderWidth: "",
      widgetBorderRadius: "",
    }),
    {}
  ),
  subscription: types.model({
    id: "",
    isActive: false,
    lastPaid: types.maybeNull(types.number),
    stripeMode: types.optional(types.string, ""),
  }),
});

const User = types
  .model({
    _id: types.optional(types.string, ""),
    userToken: types.optional(types.string, ""),
    userEmail: types.optional(types.string, ""),
    userIsAuthenticated: types.optional(types.boolean, false),
    userProjects: types.optional(types.array(Project), []),
    userStripeCustomerId: types.optional(types.string, ""),
    stripeMode: types.optional(types.string, ""),
  })
  .actions((self) => {
    const {
      pushNotification,
      setCurrentProjectId,
      setSpinnerMessage,
      setIsLoading,
    } = getParent<StoreType>(self, 1).interface;

    const setLoggedIn = (value: boolean) => {
      self.userIsAuthenticated = value;
    };

    const savingAuthenticatedUserData = (userData: UserType) => {
      applySnapshot(self, userData);
      localStorage.setItem("token", self.userToken);
      axios.defaults.headers.common.Authorization = `Bearer ${self.userToken}`;
      setLoggedIn(true);
    };

    const getUserByToken = flow(function* () {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          self.userToken = token;
          axios.defaults.headers.common.Authorization = `Bearer ${self.userToken}`;
          const authenticatedUser = yield axios.get("user/getUserByToken");
          savingAuthenticatedUserData(authenticatedUser.data.body);
        }
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "Token check error : \n" + error.message,
        });
      }
    });

    const userLogin = flow(function* (loginData: UserType) {
      try {
        const loggedInUser = yield axios.post("auth/login", loginData);
        console.log("this should be skipped");
        savingAuthenticatedUserData(loggedInUser.data.body);
      } catch (error: any) {
        console.log(error);
        pushNotification({
          type: "error",
          message: "User login error : \n" + error.message,
        });
      }
    });

    const userRegister = flow(function* (
      registerData: UserType & { recaptchaValue: string | null }
    ) {
      try {
        const registeredUser = yield axios.post("auth/register", registerData);
        savingAuthenticatedUserData(registeredUser.data.body);
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "User registration error : \n" + error.message,
        });
      }
    });

    const userLogout = flow(function* () {
      try {
        yield axios.post("user/logout");
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "User logout error : \n" + error.message,
        });
      } finally {
        localStorage.removeItem("token");
        setCurrentProjectId("");
        setLoggedIn(false);
      }
    });

    const createProject = flow(function* (
      projectData: Partial<UserProjectType>
    ) {
      try {
        const { data }: { data: UserProjectType } = yield axios.post(
          "projects/createProject",
          projectData
        );
        applySnapshot(self.userProjects, [...self.userProjects, data]);
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "Project creation error : \n" + error.message,
        });
      }
    });

    const saveProjectSettings = flow(function* (
      updatedProjectSettings: Partial<UserProjectType>
    ) {
      try {
        const response = yield axios.post(
          "projects/editProjectSettings",
          updatedProjectSettings
        );

        const updatedProject: UserProjectType = response.data;

        applySnapshot(self.userProjects, [
          updatedProject,
          ...self.userProjects.filter(
            (project) => project._id !== updatedProjectSettings._id
          ),
        ]);
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "Project creation error : \n" + error.message,
        });
      }
    });

    const deleteProject = flow(function* (projectId: string) {
      try {
        const response = yield axios.post("projects/deleteProject", {
          projectId,
        });
        const updatedProjects: UserProjectType[] = response.data;
        applySnapshot(self.userProjects, updatedProjects);
      } catch (error: any) {
        console.log(error);
        pushNotification({
          type: "error",
          message: "Project deletion error : \n" + error.message,
        });
      }
    });

    const updateProjectLocal = (updatedProject: UserProjectType) => {
      applySnapshot(self.userProjects, [
        ...self.userProjects.filter(
          (project) => project._id !== updatedProject._id
        ),
        updatedProject,
      ]);
      setCurrentProjectId(updatedProject?._id);
    };

    const ingestKnowledgeBase = flow(function* (data: FormData) {
      try {
        // Uploading files.
        yield axios.post("projects/ingestKnowledgeBase", data, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        ingestionMonitoring(
          data.get("projectId") as string,
          data.get("projectName") as string
        );
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "Knowledge base uploading error : \n" + error.message,
        });
      }
    });

    /** Ingesting monitoring connection with SSE. */
    const ingestionMonitoring = (projectId = "", projectName = "") => {
      // Creating EventSource for monitoring each file ingestion.
      const monitoringUrl =
        BASE_URL +
        `/api/v1/filesUploadProgress?userId=${self._id}&projectId=${projectId}&projectName=${projectName}`;

      const filesProgress = new EventSource(monitoringUrl);

      filesProgress.addEventListener("ingestionError", ({ data }) => {
        console.log("Files ingestion error : ", data);
        filesProgress.close();
        setIsLoading(false);
      });

      filesProgress.addEventListener("fileIngested", ({ data }) => {
        setSpinnerMessage("Ingested file: \n" + data);
      });

      filesProgress.addEventListener("ingestionCompleted", ({ data }) => {
        updateProjectLocal(JSON.parse(data));
        filesProgress.close();
        setIsLoading(false);
      });
    };

    const deleteKnowledgeBase = flow(function* (
      projectId: string,
      projectName: string
    ) {
      try {
        setIsLoading(true);
        const updatedProject = yield axios.post(
          "projects/deleteKnowledgeBase",
          {
            projectId,
            projectName,
          }
        );
        updateProjectLocal(updatedProject.data);
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "Knowledge base deletion error : \n" + error.message,
        });
      } finally {
        setIsLoading(false);
      }
    });

    const getProjectById = (_id: string) => {
      const projectToFind = self.userProjects.find(
        (project) => project._id === _id
      );
      return projectToFind ? getSnapshot(projectToFind) : null;
    };

    const createSubscription = flow(function* ({
      projectId,
      projectName,
    }: {
      [hey: string]: string;
    }) {
      try {
        const response = yield axios.post(`${BASE_URL}/api/v1/user/checkout`, {
          projectId,
          projectName,
        });
        return response.data.clientSecret;
      } catch (error: any) {
        pushNotification({
          type: "error",
          message: "Error creating subscription : \n" + error.message,
        });
      }
    });

    const getPaymentSessionStatus = flow(function* ({
      session_id,
      projectId,
    }: {
      [key: string]: string;
    }) {
      const response = yield axios.post(
        `${BASE_URL}/api/v1/user/getPaymentSessionStatus`,
        { session_id, projectId }
      );
      return response.data;
    });

    /** Updating subscription data for the project locally. If the user resisters payment method for the first time -
     * assigning him the stripe customer id locally as well. For the checkout session result page problem is that
     * the subscription data is not yet updated in the DB when the page reloads and gets it - so we rely here on the fact
     * that session is completed, therefore it is successful - and we do that change locally so the user would see
     * that subscription is active now. There must be a better way of doing this.*/
    const updateSubscriptionDataLocal = (
      subscriptionData: NewSubscriptionData
    ) => {
      setIsLoading(true);
      const {
        projectId,
        stripeProjectSubscriptionId,
        stripeCustomerId,
        lastPaid,
        isActive,
      } = subscriptionData;

      const currentProject = getProjectById(projectId);

      const updatedSubscription = {
        ...currentProject!.subscription,
        id: stripeProjectSubscriptionId,
        isActive,
        lastPaid,
      };
      const updatedProject: UserProjectType = {
        ...currentProject,
        subscription: updatedSubscription,
      };
      updateProjectLocal(updatedProject);

      if (!self.userStripeCustomerId)
        self.userStripeCustomerId = stripeCustomerId;
      setIsLoading(false);
    };

    return {
      setLoggedIn,
      getUserByToken,
      userRegister,
      userLogin,
      userLogout,
      createProject,
      saveProjectSettings,
      updateSubscriptionDataLocal,
      ingestKnowledgeBase,
      deleteKnowledgeBase,
      createSubscription,
      getPaymentSessionStatus,
      deleteProject,
      getProjectById,
    };
  });

export type UserProjectType = SnapshotOrInstance<typeof Project>;

export { User, Project };
