import { lazy } from "react";
import { Redirect } from "react-router-dom";

const Landing = lazy(
  () => import("@/views/Landing" /* webpackChunkName: "Landing" */)
);
const Cabinet = lazy(
  () => import("@/views/Cabinet" /* webpackChunkName: "Cabinet" */)
);

const routes = [
  {
    isPublic: true,
    path: "/",
    redirectTo: "/cabinet",
    exact: true,
    restricted: true,
    component: Landing,
    label: "LandingView",
  },
  {
    isPublic: false,
    path: "/cabinet",
    redirectTo: "/",
    exact: false,
    restricted: false,
    component: Cabinet,
    label: "CabinetView",
  },
  {
    isPublic: true,
    path: "*",
    redirectTo: "/",
    exact: false,
    restricted: false,
    component: () => <Redirect to="/" />,
    label: "404",
  },
];

export default routes;
