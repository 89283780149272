import React, { useState } from "react";
import store from "@/MST/store";
import Button from "@/components/elements/Button";
import { validateURL, validateProjectName } from "@/utils/validators";

import { ModalProps } from "@/types/common";

import styles from "../modalForm.module.scss";
import { UserProjectType } from "@/MST/user";

const CreateProjectModal: React.FC<ModalProps> = ({ onClose }) => {
  const [projectName, setProjectName] = useState("");
  const [projectURL, setProjectURL] = useState("");
  const [error, setError] = useState("");

  const { createProject } = store.user;
  const { setIsLoading } = store.interface;

  const onInputChange =
    (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      switch (type) {
        case "projectName": {
          setProjectName(e.target.value);
          break;
        }
        case "projectURL": {
          setProjectURL(e.target.value);
          break;
        }
      }
    };

  const validateFields = () => {
    if (!validateProjectName(projectName)) {
      setError("projectNameError");
      return false;
    }
    if (!validateURL(projectURL)) {
      setError("projectURLError");
      return false;
    }
    return true;
  };

  const onCreateClick = async (projectData: Partial<UserProjectType>) => {
    try {
      setIsLoading(true);
      closeClick();
      await createProject(projectData);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const closeClick = () => {
    clearForm();
    onClose();
  };

  const clearForm = () => {
    setProjectName("");
    setProjectURL("");
    setError("");
  };

  const createClick = () => {
    if (!validateFields()) return;
    onCreateClick({ projectName, projectURL });
    clearForm();
  };
  return (
    <div className={styles.modalForm}>
      <div className={styles.fieldsWrapper}>
        <label>
          Project name{" "}
          <span className="hintText">leters, numbers, dashes are allowed</span>
          <div className="inputWrapper"> <input
            type="text"
            autoFocus={true}
            className={error === "projectNameError" ? "error" : ""}
            value={projectName}
            placeholder="project-name"
            onChange={onInputChange("projectName")}
          /></div>
         
        </label>
        <label>
          Project URL
          <div className="inputWrapper"><input
            type="url"
            className={error === "projectURLError" ? "error" : ""}
            value={projectURL}
            placeholder="https://example.com"
            onChange={onInputChange("projectURL")}
          /></div>
          
        </label>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button text="Create" onClick={createClick} />
        <Button text="Cancel" onClick={closeClick} />
      </div>
    </div>
  );
};

export default CreateProjectModal;
