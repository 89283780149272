import { useEffect, Suspense, memo } from "react";
import { Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import routes from "./routes";

import { PrivateRoute, PublicRoute } from "@/components/routes";
import { AppBar, Spinner } from "./components";
import { createBackground } from "./particles.js";
import store from "./MST/store";

import Modal from "./components/ModalForms/Modal/Modal";
import styles from "@/scss/main.module.scss";

const ParticleCanvas = memo(() => {
  useEffect(() => {
    createBackground();
  }, []);
  return <canvas id="canvas1" />;
});

function App() {
  const { getUserByToken, userIsAuthenticated } = store.user;
  const { setCurrentProjectId } = store.interface;

  useEffect(() => {
    if (localStorage.getItem("token") && !userIsAuthenticated) getUserByToken();
  }, [getUserByToken, userIsAuthenticated]);

  useEffect(() => {
    const storedCurrentProjectId = localStorage.getItem("currentProjectId");
    if (storedCurrentProjectId) setCurrentProjectId(storedCurrentProjectId);
  }, []);

  /*
   * If there's a token in localStorage on the initial page load / page reload  -
   * the next lines prevent the login page flicker, showing spinner instead.
   */
  if (localStorage.getItem("token") && !userIsAuthenticated)
    return <Spinner text="Checking token..." />;

  return (
    <div className={styles.appMain}>
      <AppBar /> {userIsAuthenticated ? <div></div> : null}
      <main className={styles.main}>
        <Suspense fallback={<Spinner />}>
          <Switch>
            {routes.map(({ isPublic, ...props }) =>
              isPublic ? (
                <PublicRoute key={props.label} {...props} />
              ) : (
                <PrivateRoute key={props.label} {...props} />
              )
            )}
          </Switch>
        </Suspense>
        <Modal />
      </main>
      <ParticleCanvas />
    </div>
  );
}

export default observer(App);
